<template>
<div class="login">
  <h1 class="title grey--text pt-2">密码重置</h1>
  <v-container>
    <v-layout justify-end wrap>
      <v-flex xs7>
        <v-select
          v-model="recoveryChannelCode"
          :items="recoveryChannels"
          item-text="channel"
          item-value="code"
          label="重置密码方式"
          single-line
          :rules="[required]"
          :disabled=true
        ></v-select>
      </v-flex>

      <v-flex xs7>
        <v-text-field
          label="已绑定的邮箱/手机"
          name="account"
          v-model="account"
          outline
          block
          :rules="[required]"
        ></v-text-field>
      </v-flex>

      <v-flex xs7>
        <v-btn
          color="primary"
          :disabled="requestSent"
          @click="requirePasswordResetLink(account, [recoveryChannelCode])" block>
          发送重置链接<span v-if="timerCountDown>0">({{timerCountDown}}s)</span>
        </v-btn>
      </v-flex>

    </v-layout>
  </v-container>
  <v-snackbar
    v-model="snackbar"
    :timeout="snackbarTimeout"
  >
    {{ snackbarMsg }}
    <v-btn
      color="primary"
      text
      @click="snackbar = false"
    >
      知道了
    </v-btn>
  </v-snackbar>
  <v-alert class="ma-5" type="success" v-if="true">
    <strong>密码重置</strong>
    <p>如果您的账户没有绑定邮箱，请联系秋邀进行人工邮箱绑定。绑定后才能进行重置操作。</p>
  </v-alert>
</div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import consola from 'consola'

export default {
  components: {
  },
  data () {
    return {
      recoveryChannelCode: 1, // login as tutor
      recoveryChannels: [
        { code: 1, channel: '发送至邮箱' }
      ],
      account: '',
      required: (value) => !!value || '必填',
      snackbar: false,
      snackbarTimeout: 5000,
      snackbarMsg: '',
      requestSent: false,
      timerCountDown: 0
    }
  },
  watch: {
    timerCountDown: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCountDown--
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  async mounted () {
    consola.info('#Page: password-reset-link')
  },
  methods: {
    async requirePasswordResetLink (account, recoveryChannelCodes) {
      try {
        this.requestSent = true
        this.timerCountDown = 30
        setTimeout(() => {
          this.requestSent = false
          consola.log(`time out finished`)
        }, this.timerCountDown * 1000)
        const res = await AuthenticationService.generateResetPasswordLink(account, recoveryChannelCodes)
        this.showSnackBar(res.data.msg, 5000)
      } catch (error) {
        this.showSnackBar(error.response.data.msg, this.snackbarTimeout)
        this.requestSent = false
      }
    },
    showSnackBar (snackbarMsg, timeout) {
      this.snackbar = true
      this.snackbarTimeout = timeout
      this.snackbarMsg = snackbarMsg
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
